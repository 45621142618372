import React from 'react';
import Header from './components/header';
import About from './components/about';
import Tokenomics from './components/tokenomics';
import HowToBuy from './components/howtobuy';
import Footer from './components/footer';

function App() {
  return (
    <div>
      <Header />
      <About />
      <Tokenomics />
      <HowToBuy />
      <Footer />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './tokenomics.css';

function Tokenomics() {
  


  return (
    <section className="tokenomics-section py-5">
      <Container>
        <h2 className="text-center">Tokenomics</h2>
        <Row className="text-center mt-4">
          <Col md={4}>
            <h4>Contract Address</h4>
            <p>0x55c3A56e638e96C91F98735CC86F60a6820e6A44</p>
          </Col>
          <Col md={4}>
            <h4>Total Supply</h4>
            <p>1,000,000,000,000 GAV</p>
          </Col>
          
        </Row>
      </Container>
    </section>
  );
}

export default Tokenomics;

import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './header.css';

function Header() {
  return (
    <header className="hero-section text-center text-light">
      <Container>
        <img src="/logo.png" alt="GavCoin Logo" className="logo mb-3" />
        <h1 className="display-4 fw-bold">GavCoin</h1>
        <p className="lead">The First-Ever MemeCoin on Ethereum</p>
        <Button
          href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x55c3A56e638e96C91F98735CC86F60a6820e6A44"
          target="_blank"
          rel="noopener noreferrer"
          className="buy-button"
          size="lg"
          variant="warning"
        >
          Buy GavCoin
        </Button>
      </Container>
    </header>
  );
}

export default Header;

import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="bg-dark text-light py-3">
      <Container className="text-center">
        <p>&copy; 2024 GavCoin. All Rights Reserved.</p>
        <p>
          Follow us on <a href="https://Twitter.com/gavcoin_erc" className="text-warning">Twitter</a> and <a href="https://t.me/GavPortal" className="text-warning">Telegram</a>
        </p>
      </Container>
    </footer>
  );
}

export default Footer;

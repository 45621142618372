import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

function About() {
  return (
    <section className="about-section py-5">
      <Container>
        <h2 className="text-center mb-4">About GavCoin</h2>
        <p className="text-center lead">
          GavCoin is the OG memecoin, created in 2014 by Ethereum lead developer Gavin Wood. 
          It’s the first-ever conceptualized meme token on Ethereum, pre-dating ethereum itself. 
          This coin is historically significant, as highlighted by an original tweet from the Ethereum Foundation and a presentation by Gavin Wood Himself.
        </p>
        
        <Row className="align-items-center mt-4">
          {/* Ethereum Screenshot */}
          <Col md={6} className="text-center">
            <a href="https://x.com/ethereum/status/453589682027507712" target="_blank" rel="noopener noreferrer">
              <Image src="/eth-screensho.png" alt="Ethereum Foundation Tweet" fluid rounded className="mt-4" />
            </a>
          </Col>
          
          {/* GavCoin Video */}
          <Col md={6} className="text-center">
            <video controls width="100%" className="mt-4">
              <source src="/gavcoinvideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;

import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';

function HowToBuy() {
  return (
    <section id="how-to-buy" className="how-to-buy-section py-5">
      <Container>
        <h2 className="text-center">How to Buy GavCoin</h2>
        <ListGroup as="ol" numbered className="mt-4">
          <ListGroup.Item>Install a Web3 wallet like MetaMask.</ListGroup.Item>
          <ListGroup.Item>Add Ethereum to your wallet.</ListGroup.Item>
          
          <ListGroup.Item>Go to <a href="https://uniswap.org/" target="_blank" rel="noopener noreferrer">Uniswap V2</a> and connect your wallet.</ListGroup.Item>
          <ListGroup.Item>Enter GavCoin’s contract address: 0x55c3A56e638e96C91F98735CC86F60a6820e6A44</ListGroup.Item>
          <ListGroup.Item>Swap your ETH for GAV.</ListGroup.Item>
        </ListGroup>
        <p className="mt-3 text-center text-danger">
          <strong>Disclaimer:</strong> Cryptocurrency investments involve risk. Please do your own research before buying.
        </p>
      </Container>
    </section>
  );
}

export default HowToBuy;
